import Vue from 'vue'
import VueRouter from 'vue-router'
import Main from '../views/Main.vue'
import wxPay from '../views/WxPay.vue'
// import Home from '../views/Home.vue'
// import User from '../views/User.vue'
// import Mall from '../views/Mall.vue'
// import PageOne from '../views/PageOne.vue'
// import PageTwo from '../views/PageTwo.vue'
import Login from '../views/Login.vue'
import wxPayBefore from '../views/WxPayBefore.vue'
Vue.use(VueRouter)

//1 创建路由组件
// 2 将路由和组件进行映射
const routes = [
    //主路由
    {
        path: '/',
        component: Main,
        name : 'Main',
        redirect : '/home',
        children: [
            // //子路由
            // { path: 'home',name:"home", component: Home },//首页
            // { path: 'user',name:"user", component: User }, //用户管理
            // { path: 'mall', name:"mall",component: Mall},  //商品管理
            // { path: 'page1',name:"page1", component: PageOne },
            // { path: 'page2',name:"page2", component: PageTwo },
        ]
    },
    {
        path: '/login',
        name:'login',
        component: Login
    
    },
    {
        path: '/wxPay',// 微信支付
        name:'wxPay',
        component: wxPay,
    }
    ,
    {
        path: '/wxPayBefore',// 微信支付
        name:'wxPayBefore',
        component: wxPayBefore,
      }
]

// 3. 创建 router 实例，然后传 `routes` 配置
// 你还可以传别的配置参数, 不过先这么简单着吧。
const router = new VueRouter({
    routes // (缩写) 相当于 routes: routes
})

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location, onResolve, onReject) {
if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
return originalPush.call(this, location).catch(err => err)
}

export default router


