<template>
  <div class="main">
    <div class="login-container">
      <el-tabs v-model="activeName">
        <el-tab-pane label="手机验证码登陆" name="first">
          <el-form
            ref="phoneForm"
            :inline="true"
            :model="phoneForm"
            :rules="codeRules"
          >
            <el-form-item label="手机号" prop="phone">
              <el-input
                v-model="phoneForm.phone"
                placeholder="请输入手机号码"
              ></el-input>
            </el-form-item>
            <el-form-item label="验证码" prop="code">
              <el-input v-model="phoneForm.code" placeholder="验证码">
                <template #append>
                  <el-button type="primary" @click="getCode" size="mini">{{
                    countdown > 0 ? countdown + "s" : "获取验证码"
                  }}</el-button>
                </template></el-input
              >
            </el-form-item>
            <el-form-item style="margin-left: 105px; margin-top: 10px">
              <el-button @click="codeLogin" type="primary"> 登陆 </el-button>
            </el-form-item>
          </el-form>
        </el-tab-pane>
        <el-tab-pane label="密码登陆" name="second">
          <el-form ref="form" :inline="true" :model="form" :rules="rules">
            <el-form-item label="用户名" prop="name">
              <el-input
                v-model="form.name"
                placeholder="请输入账号/手机号"
              ></el-input>
            </el-form-item>
            <el-form-item label="密码" prop="password">
              <el-input
                type="password"
                v-model="form.password"
                placeholder="请输入密码"
                style="margin-left: 12px"
              ></el-input>
            </el-form-item>
            <el-form-item style="margin-left: 105px; margin-top: 10px">
              <el-button @click="passwordLogin" type="primary">
                登陆
              </el-button>
            </el-form-item>
          </el-form>
        </el-tab-pane>
      </el-tabs>
      <el-button
        @click="registerAction"
        type="text"
        size="mini"
        class="registerClass"
        >点击注册</el-button
      >
    </div>
    <el-dialog
      title="注册账号"
      :visible.sync="registerDialogValue"
      width="50%"
      :modal="false"
      :before-close="registerDialogClose"
      :close-on-press-escape="true"
    >
      <el-form
        :model="registerForm"
        ref="registerForm"
        :rules="registerRules"
        label-width="100px"
      >
        <el-form-item label="姓名" prop="name">
          <el-input
            v-model="registerForm.name"
            placeholder="请输入姓名"
          ></el-input>
        </el-form-item>
        <el-form-item label="手机号码" prop="phone">
          <el-input
            v-model="registerForm.phone"
            placeholder="请输入手机号码"
          ></el-input>
        </el-form-item>
        <el-form-item label="验证码" prop="code">
          <el-input v-model="registerForm.code" placeholder="请输入验证码">
            <template slot="append">
              <el-button
                type="primary"
                @click="getRegisterCode"
                size="mini"
                :disabled="countdown > 0"
                >{{ countdown > 0 ? countdown + "s" : "获取验证码" }}</el-button
              >
            </template>
          </el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="handleRegister">注册</el-button>
          <el-button @click="cancelRegister">取消</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import Cookie from "js-cookie";
import { getMenu } from "../api/";
import { getCode } from "../api/";
import { login } from "../api/";
import { checkVerifyCode, register } from "../api/";
import { getUserInfoByPhone } from "../api/";
import qs from "qs";
export default {
  data() {
    return {
      registerRules: {
        name: [{ required: true, message: "请输入电话", trigger: "blur" }],
        phone: [
          { required: true, message: "请输入电话", trigger: "blur" },
          { min: 11, max: 11, message: "请输入11位电话号码", trigger: "blur" },
        ],
        code: [{ required: true, message: "请输入验证码", trigger: "blur" }],
      },
      registerDialogValue: false,
      countdown: 0, // 添加倒计时功能
      activeName: "second",
      form: {
        name: "",
        password: "",
      },
      registerForm: {
        name: "",
        phone: "",
        code: "",
      },
      registerCode: "",
      registerOutId: "",
      phoneForm: {
        phone: "",
        code: "",
      },
      code: "",
      outId: "",
      rules: {
        name: [{ required: true, message: "请输入用户名", trigger: "blur" }],
        password: [{ required: true, message: "请输入密码", trigger: "blur" }],
      },
      codeRules: {
        phone: [
          { required: true, message: "请输入手机号码", trigger: "blur" },
          {
            pattern: /^1[3456789]\d{9}$/,
            message: "手机号码格式不正确",
            trigger: "blur",
          },
        ],
        code: [{ required: true, message: "请输入验证码", trigger: "blur" }],
      },
    };
  },
  methods: {
    registerAction() {
      this.registerDialogValue = true;
    },
    //注册发送验证码
    getRegisterCode() {
      const phone = this.registerForm.phone;
      const params = qs.stringify({ phone: phone });
      //获取验证码
      getCode("/web/manage/sendVerifyCodeMsg", params).then((response) => {
        if (response.data.code === 200) {
          this.outId = response.data.data;
          this.$message({
            type: "success",
            message: "获取成功!",
          });
          // 启动倒计时并禁用按钮
          this.countdown = 60;
          const timer = setInterval(() => {
            this.countdown--;
            if (this.countdown <= 0) {
              clearInterval(timer);
            }
          }, 1000);
        } else {
          this.$message({
            type: "error",
            message: response.data.message,
          });
        }
      });
    },
    handleRegister() {
      //校验验证码
      const params = qs.stringify({
        phone: this.registerForm.phone,
        code: this.registerForm.code,
        outId: this.outId,
      });
      checkVerifyCode("/web/manage/checkVerifyCode", params).then(
        (response) => {
          if (response.data.code === 200) {
            register(this.registerForm).then((response) => {
              if (response.data.code === 200) {
                this.$message({
                  type: "success",
                  message: "注册成功!",
                });
                this.$refs.registerForm.resetFields();
                this.registerDialogValue = false;
              } else {
                this.$message({
                  type: "error",
                  message: response.data.message,
                });
              }
            });
          } else {
            this.$message({
              type: "error",
              message: response.data.message,
            });
          }
        }
      );
    },
    cancelRegister() {
      console.log("sadsadasdsa ");
      this.$refs.registerForm.resetFields();
      this.registerDialogValue = false;
    },
    registerDialogClose() {
      console.log("sadsadasdsa ");
      this.$refs.registerForm.resetFields();
      this.registerDialogValue = false;
    },
    getCode() {
      const phone = this.phoneForm.phone;
      const params = qs.stringify({ phone: phone });
      //获取验证码
      getCode("/web/manage/sendVerifyCodeMsg", params).then((response) => {
        if (response.data.code === 200) {
          this.outId = response.data.data;
          this.$message({
            type: "success",
            message: "获取成功!",
          });
          // 启动倒计时并禁用按钮
          this.countdown = 60;
          const timer = setInterval(() => {
            this.countdown--;
            if (this.countdown <= 0) {
              clearInterval(timer);
            }
          }, 1000);
        } else {
          this.$message({
            type: "error",
            message: response.data.message,
          });
        }
      });
    },
    //验证码登陆
    codeLogin() {
      let obj = {
        phone: this.phoneForm.phone,
        outId: this.outId,
        code: this.phoneForm.code,
      };
      console.log(obj, "obj");
      const params = qs.stringify(obj);
      console.log(this.phoneForm, "phoneForm");
      const paramPhone = qs.stringify({ phone: this.phoneForm.phone });
      checkVerifyCode("/web/manage/checkVerifyCode", params).then(
        (response) => {
          if (response.data.code == 200) {
            //根据手机号码获取用户信息
            getUserInfoByPhone("/web/user/getUserInfoByPhone", paramPhone).then(
              (response) => {
                if (response.data.code == 200) {
                  // 2 登陆成功
                  this.$message.success("登陆成功");
                  // 2.1 将token存入cookie
                  console.log("data", response.data);
                  Cookie.set("token", response.data.data);
                  //将用户信息报错到localStorage
                  localStorage.setItem(
                    "userInfo",
                    JSON.stringify(response.data.data)
                  );

                  const roleParam = qs.stringify({
                    roleId: response.data.data.role,
                  });
                  // 获取菜单权限
                  getMenu("/web/manage/permission/getMenu", roleParam).then(
                    (menuData) => {
                      console.log("menuData", menuData);
                      this.$store.commit("setMenu", menuData.data.data);
                      this.$store.commit("addMenu", this.$router);
                      //   //跳转到首页;
                      //   this.$router.push({ path: menuData.data.data[0].path });
                      //判断menuData.data.data的元素的children属性是否为空,如果不是空跳转到children的第一个对象的path,如果是空跳转到menuData.data.data的path
                      if (menuData.data.data[0].children.length > 0) {
                        this.$router.push({
                          path: menuData.data.data[0].children[0].path,
                        });
                      } else {
                        this.$router.push({ path: menuData.data.data[0].path });
                      }
                    }
                  );
                } else {
                  this.$message.error(response.data.message);
                }
              }
            );
          } else {
            this.$message.error(response.data.message);
          }
        }
      );
    },
    //密码登陆
    passwordLogin() {
      console.log(this.form, "form");
      //根据用户名和密码登陆  request.js 中的login方法  form 是参数
      login(this.form).then(({ data }) => {
        console.log(data, "data");
        if (data.code === 200) {
          // 2 登陆成功
          this.$message.success("登陆成功");
          // 2.1 将token存入cookie
          console.log("data", data);
          Cookie.set("token", data.data);
          //将用户信息报错到localStorage
          localStorage.setItem("userInfo", JSON.stringify(data.data));

          const roleParam = qs.stringify({ roleId: data.data.role });
          // 获取菜单权限
          getMenu("/web/manage/permission/getMenu", roleParam).then(
            (menuData) => {
              console.log("menuData", menuData);
              this.$store.commit("setMenu", menuData.data.data);
              this.$store.commit("addMenu", this.$router);
              //跳转到首页;
              //   this.$router.push({ path: menuData.data.data[0].path });
              //判断menuData.data.data的元素的children属性是否为空,如果不是空跳转到children的第一个对象的path,如果是空跳转到menuData.data.data的path
              if (menuData.data.data[0].children.length > 0) {
                this.$router.push({
                  path: menuData.data.data[0].children[0].path,
                });
              } else {
                this.$router.push({ path: menuData.data.data[0].path });
              }
            }
          );
        } else {
          // 3 登陆失败
          this.$message.error(data.message);
        }
      });
    },
  },
};
</script>
<style lang="less" scoped>
.main {
  display: flex;
  background: url("../assets/登陆页面背景图.png");
  background-size: 100% 100%;
  position: fixed; /* 充满全屏 */
  height: 100%; //设置div高度
  width: 100%; //设置div宽度
  .login-container {
    width: 350px;
    margin: 100px auto;
    border: 1px solid #eaeaea;
    margin: auto;
    padding: 35px 35px 15px 35px;
    background-color: #fff;
    border-radius: 15px;
    box-shadow: 0 0 25px #cac6c6;
    box-sizing: border-box;
    .login-title {
      text-align: center;
      font-size: 20px;
      margin-bottom: 40px;
      color: #505458;
    }
    .registerClass {
      font-size: 10px;
      margin-left: 120px;
      color: #427c44;
    }
    .el-input {
      width: 198px;
    }
  }
}
</style>
