<template>
  <!-- 展示微信订单信息.微信支付前 -->
  <el-card class="payCard">
    <div slot="header" class="payHeader">
      <span>微信支付</span>
    </div>
    <div class="payDetail">
      <el-form :model="form" label-width="80px">
        <el-form-item label="订单号:">
          <span>{{ form.orderId }}</span>
        </el-form-item>
        <el-form-item label="订单金额:">
          <span>{{ form.totalAmount / 100 }} 元</span>
        </el-form-item>
        <el-form-item label="订单信息:">
          <span>{{ form.remark }}</span>
        </el-form-item>
      </el-form>
    </div>
  </el-card>
</template>
<script src="https://res.wx.qq.com/open/js/jweixin-1.6.0.js"></script>
<script>
import { getJuiceRecordDetail, createOrderTraceCode } from "../api";
import qs from "qs";
export default {
  data() {
    return {
      orderId: this.$route.query.orderId,
      form: {
        orderId: "",
        price: "",
        remark: "",
      },
    };
  },
  methods: {
    getJuiceRecordDetail() {
      const recordIdParam = qs.stringify({ orderId: this.orderId });
      getJuiceRecordDetail("app/order/getOrderById", recordIdParam).then(
        (res) => {
          if (res.data.code === 200) {
            this.form = res.data.data;
            console.log("res.data.data", res.data.data);
            if (res.data.data.tractCode != 0 && res.data.data.tractCode != "") {
              this.$message.error(
                "二维码已经使用,请在扫码的手机上完成支付,或者重新生成二维码"
              );
            } else {
              if (res.data.data.payStatus == 1) {
                this.form = res.data.data;
                this.makeOrder();
              }
            }
          } else {
            this.$message.error("订单不存在或者订单已支付");
          }
        }
      );
    },
    createOrderTraceCode() {
      const recordIdParam = qs.stringify({ orderId: this.orderId });
      createOrderTraceCode("app/wechat/createOrderTraceCode", recordIdParam)
        .then((res) => {
          if (res.data.code === 200) {
            this.form = res.data.data;
            this.makeOrder();
          } else {
            this.$message.error(res.data.message);
          }
        })
        .catch((error) => {
          console.error("生成二维码失败", error);
          this.$message.error(res.data.message);
        });
    },
    makeOrder() {
      try {
        console.log("makeOrder");
        const APPID = "wx86cecfe85246107d"; // 替换为您的公众号的唯一标识
        const REDIRECT_URI = encodeURIComponent(
          `https://manage-mis.threeapple.cn/api/app/wechat/callback.do?orderId=` +
            this.orderId
        ); // 替换为您的授权后重定向的回调链接地址，并使用 encodeURIComponent 进行处理
        console.log("REDIRECT_URI", REDIRECT_URI);
        const SCOPE = "snsapi_base"; // 替换为您的应用授权作用域npm
        const STATE = "3d6be0a4035d839573b04816624a415e"; // 替换为您的 state 参数值
        const authUrl = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${APPID}&redirect_uri=${REDIRECT_URI}&response_type=code&scope=${SCOPE}&state=${STATE}#wechat_redirect`;
        // 使用 window.location.href 实现页面跳转到微信授权页面
        window.location.href = authUrl;
      } catch (e) {
        console.error("授权过程出错", error);
        // 在页面上显示错误信息
        document.getElementById("error-message").innerText =
          "微信授权失败: " + error.message;
        // 或者使用 alert 弹出错误信息
        alert("微信授权失败: " + error.message);
      }
    },
  },
  mounted() {
    console.log("orderId", this.orderId);
    // this.getJuiceRecordDetail();
    this.createOrderTraceCode();
  },
};
</script>
<style lang="less" scoped>
.payCard {
  margin-top: 20px;
  width: 90%;
  margin-left: 20px;
}
.payHeader {
  background-color: #fcfcfc;
  color: #000;
  font-size: 20px;
  text-align: center;
}
.payDetail {
  margin-top: 20px;
}
</style>
