<template>
  <div id="app">
    <el-row>
      <router-view></router-view>
    </el-row>
  </div>
</template>

<script>
export default {
  name: "App",
};
</script>
<style lang="less">
html,
body,
h3,
p {
  margin: 0;
  padding: 0;
}
</style>
