import router from "@/router";
import axios from "axios";
import Cookies from 'js-cookie';

const http = axios.create({
    baseURL: "/",
    timeout: 5000,
    withCredentials: true,
});
http.defaults.baseURL = 'https://manage-mis.threeapple.cn/api/';
// http.defaults.baseURL = process.env.VUE_APP_URL;  // 通过环境变量设置请求的前缀
// http.defaults.baseURL = 'http://127.0.0.1:9001';

//请求拦截器
// 请求拦截器
http.interceptors.request.use(
    config => {
      const token = Cookies.get('token')
      if (token) {
        config.headers['Authorization'] = `Bearer ${token}` // 让每个请求携带 token
      }
      return config
    },
    error => {
      return Promise.reject(error)
    }
  )

//响应拦截器
http.interceptors.response.use(
    response => {
      return response
    },
    error => {
        console.log("error,response",error.sta)
        if (error.response.status === 401) {
          console.log(error.response.status,"error.response.status")
        // 处理响应错误
            router.push({ name: 'login' }).catch(err => {
              console.error('Navigation error:', err)
            })
      }
      return Promise.reject(error)
    }
  );

export default http;
