<template>
  <el-dialog
    :visible.sync="internalDialogVisible"
    title="个人信息"
    margin-top="20vh"
    width="50%"
    :before-close="beforeClose"
    class="userProfileDialog"
    center
  >
    <el-card class="user-from">
      <div class="form-item">
        <label>姓名</label>
        <el-input
          v-model="userFrom.name"
          :disabled="this.updateUserFrom"
        ></el-input>
      </div>
      <div class="form-item">
        <label>地址</label>
        <el-input
          v-model="userFrom.address"
          :disabled="this.updateUserFrom"
        ></el-input>
      </div>
      <div class="form-item">
        <label>手机号码</label>
        <el-input v-model="userFrom.phone" :disabled="true"></el-input>
      </div>
      <div class="buttonList">
        <el-button
          size="small"
          type="primary"
          round
          class="update-user-button"
          @click="updateUser"
          v-if="this.updateUserFrom"
          >修改</el-button
        >
        <el-button
          size="small"
          type="warning"
          round
          class="cancel"
          @click="cancel"
          v-if="!this.updateUserFrom"
          >取消</el-button
        >
        <el-button
          size="small"
          type="success"
          round
          class="save-user-button"
          @click="save"
          v-if="!this.updateUserFrom"
          >保存</el-button
        >
      </div>
    </el-card>
  </el-dialog>
</template>

<script>
import { getUserInfoById, editUser } from "../api";
import qs from "qs";
export default {
  props: {
    dialogVisible: Boolean,
  },
  data() {
    return {
      internalDialogVisible: this.dialogVisible,
      userFrom: {
        name: "",
        address: "",
        phone: "",
        id: "",
      },
      userInfo: {
        name: "",
        role: "",
        id: "",
      },
      updateUserFrom: true,
    };
  },
  watch: {
    dialogVisible(newValue) {
      this.internalDialogVisible = newValue;
    },
  },
  //获取localsession
  created() {
    this.userInfo = JSON.parse(localStorage.getItem("userInfo"));
    console.log(this.userInfo, "userInfo");
  },
  methods: {
    beforeClose(done) {
      this.$store.commit("updateUserProfileDialogVisibility", false);
      done();
    },
    getUserInfo() {
      console.log("getUserInfo");
      const userIdparam = qs.stringify({
        id: this.userInfo.id,
      });
      getUserInfoById("web/user/getUserInfoById", userIdparam).then((res) => {
        console.log(res);
        this.userFrom.id = res.data.data.id;
        this.userFrom.name = res.data.data.name;
        this.userFrom.address = res.data.data.address;
        this.userFrom.phone = res.data.data.phone;
      });
    },
    updateUser() {
      this.updateUserFrom = false;
    },
    cancel() {
      this.updateUserFrom = true;
    },
    save() {
      console.log("Before save:", this.userFrom); // 添加这行调试输出
      //保存用户信息
      this.updateUserFrom = true;
      editUser(this.userFrom).then((res) => {
        if (res.data.code === 200) {
          this.$message({
            message: "保存成功",
            type: "success",
          });
          this.getUserInfo();
        } else {
          this.$message({
            message: res.data.message,
            type: "error",
          });
        }
      });
    },
  },
  mounted() {
    this.getUserInfo();
  },
};
</script>

<style lang="less" scoped>
/* 如果需要，可以在这里添加样式 */
.userProfileDialog {
  //个人信息居中
  .el-dialog__body {
    text-align: center;
  }
  .user-from {
    padding: 20px;
    .form-item {
      margin-bottom: 10px;
      label {
        display: inline-block;
        width: 80px;
        text-align: right;
        margin-right: 10px;
      }
      .el-input {
        width: calc(100% - 300px);
        border: none; /* 去掉边框 */
        background-color: transparent; /* 设置透明背景色 */
        box-shadow: none; /* 去掉阴影效果 */
      }
    }
    .buttonList {
      margin-top: 50px;
      .update-user-button {
        margin-left: 100px;
      }
      .cancel {
        margin-left: 100px;
      }
    }
  }
}
</style>
