import Mock from "mockjs";
import HomeApi from "./mockServeData/home";
import user from "./mockServeData/user";
import permission from "./mockServeData/permission";

Mock.mock('/api/home/getData', HomeApi.getStatisticalData);

//用户列表数据
Mock.mock('/api/user/add','post', user.createUser);
Mock.mock(/api\/user\/getuser/, user.getUserList);
Mock.mock('/api/user/del','post', user.deleteUser);
Mock.mock('/api/user/edit', 'post', user.updateUser);
Mock.mock(/api\/permission\/getMenu/, 'post', permission.getMenu)