<template>
  <div>
    <el-container>
      <el-aside width="auto">
        <!-- 侧边栏 -->
        <common-aside />
      </el-aside>
      <el-container>
        <el-header>
          <!-- 头部 -->
          <common-header />
        </el-header>
        <common-tag />
        <el-main>
          <!---路由出口 -->
          <!---路由匹配到的组件将渲染在这里 -->
          <router-view />
          <!-- 添加 "个人中心" 对话框 -->
          <UserDetailDialog
            :dialogVisible="userProfileDialogVisible"
          ></UserDetailDialog>
          <UpdatePasswordDialog
            :dialogVisible="updatePasswordDialogVisible"
          ></UpdatePasswordDialog>
        </el-main>
      </el-container>
    </el-container>
  </div>
</template>
<script>
import commonAside from "../components/CommonAside.vue";
import CommonHeader from "@/components/CommonHeader.vue";
import CommonTag from "@/components/CommonTag.vue";
import UserDetailDialog from "@/components/UserDetailDialog.vue";
import UpdatePasswordDialog from "@/components/UpdatePasswordDialog.vue";
export default {
  data() {
    return {};
  },
  computed: {
    userProfileDialogVisible() {
      return this.$store.state.userProfileDialogVisible;
    },
    updatePasswordDialogVisible() {
      return this.$store.state.updatePasswordDialogVisible;
    },
  },
  components: {
    commonAside,
    CommonHeader,
    CommonTag,
    UserDetailDialog,
    UpdatePasswordDialog,
  },
};
</script>
<style scoped>
.el-header {
  padding: 0;
}
</style>
