
export default {
  state: {
    isCollapse: false, //控制左侧菜单的展开还是收起
    tabsList: [
    //   {
    //     path: "/",
    //     name: "home",
    //     label: "首页",
    //     icon: "s-home",
    //     url: "Home/Home",
    //     }, //存放面包屑的数组
        ],
        menu:[] //存放左侧菜单的数组
  },
  mutations: {
    //控制左侧菜单的展开还是收起的方法
    collapseMenu(state) {
      state.isCollapse = !state.isCollapse;
      },
      
      //更新面包屑数据
      selectMenu(state, val) {
        if(val.name !== 'home'){
          state.tabsList.findIndex(item => item.name === val.name) === -1 ? state.tabsList.push(val) : '';
        }
      }, 
      //删除置顶的tag数据
      closeTag(state, item) { 
          const index = state.tabsList.findIndex(val => val.name === item.name) 
          state.tabsList.splice(index, 1);
      },

      //设置menu的数据
      setMenu(state, val) { 
          state.menu = val;
        //   Cookies.set('menu', JSON.stringify(val));
        window.localStorage.setItem('menu', JSON.stringify(val));
      },

      //动态注册路由
      addMenu(state, router) {
        //   if (!Cookies.get('menu')) {
        //       return
        //   }
          if (!window.localStorage.getItem('menu')) { 
                return
          }
        //   const menu = JSON.parse(Cookies.get('menu'));
          const menu = JSON.parse(window.localStorage.getItem('menu'));
          state.menu = menu;
          //处理动态路由的数据
          const menuArray = [];
          menu.forEach(item => {
                if (item.children.length > 0) {
                    item.children = item.children.map(item => {
                        item.component = () => import('@/views/' + item.url);
                        
                          //按钮 保存到 meta  permissions属性
                        if (item.buttonList.length > 0) {
                            item.meta = { permissions: item.buttonList }
                        }                        
                        return item
                    });
                    menuArray.push(...item.children);
                } else {
                    item.component = () => import('@/views/' + item.url );
                    menuArray.push(item);
                }
              
              //按钮
              if (item.buttonList.length > 0) {
                item.meta = { permissions: item.buttonList }
              }
          });
          console.log('menuArray', menuArray);
          //动态注册路由
          menuArray.forEach(item => {
              router.addRoute('Main', item);
          });
      }
    },
    computed: {
        filteredRoutes() {
          return this.$router.options.routes.filter(route => !route.meta.hideInNav);
        },
      },
};
