<template>
  <!-- 支付结果页面 -->
  <div class="payment-result-container">
    <div v-if="paySuccess" class="success-message">
      <!-- 成功支付的消息 -->
      <p>支付成功！</p>
    </div>

    <div v-if="!paySuccess && !retryingPayment" class="retry-container">
      <!-- 重新支付按钮 -->
      <button @click="retryPayment" class="retry-button">重新支付</button>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      appId: "",
      timeStamp: "",
      nonceStr: "",
      package: "",
      signType: "",
      paySign: "",
      paySuccess: false,
      retryingPayment: false, // 添加一个状态标志用于判断是否正在尝试重新支付
    };
  },
  mounted() {
    // 获取 URL 参数
    this.appId = this.$route.query.appId;
    this.timeStamp = this.$route.query.timeStamp;
    this.nonceStr = this.$route.query.nonceStr;
    this.package = this.$route.query.package;
    this.signType = this.$route.query.signType;
    this.paySign = this.$route.query.paySign;

    // 注入配置
    if (typeof WeixinJSBridge == "undefined") {
      if (document.addEventListener) {
        document.addEventListener(
          "WeixinJSBridgeReady",
          this.onBridgeReady,
          false
        );
      } else if (document.attachEvent) {
        document.attachEvent("WeixinJSBridgeReady", this.onBridgeReady);
        document.attachEvent("onWeixinJSBridgeReady", this.onBridgeReady);
      }
    } else {
      this.onBridgeReady();
    }
  },
  methods: {
    onBridgeReady() {
      WeixinJSBridge.invoke(
        "getBrandWCPayRequest",
        {
          appId: this.appId, // 公众号ID，由商户传入
          timeStamp: this.timeStamp, // 时间戳，自1970年以来的秒数
          nonceStr: this.nonceStr, // 随机串
          package: this.package,
          signType: this.signType, // 微信签名方式
          paySign: this.paySign, // 微信签名
        },
        (res) => {
          if (res.err_msg == "get_brand_wcpay_request:ok") {
            // 使用以上方式判断前端返回
            // 微信团队郑重提示：res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
            this.paySuccess = true;
          } else {
            this.paySuccess = false;
            this.retryingPayment = false; // 用户取消支付后，重置重新支付的状态标志
          }
        }
      );
    },
    retryPayment() {
      this.retryingPayment = true; // 用户点击重新支付，设置重新支付的状态标志
      this.onBridgeReady(); // 重新调用微信支付
    },
  },
};
</script>
<style scoped>
.payment-result-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh; /* 让页面充满整个视口高度 */
  background-color: #f7f7f7; /* 设置一个背景色 */
}

.success-message {
  color: #4caf50; /* 成功消息的文字颜色 */
  font-size: 20px;
  text-align: center;
  margin-bottom: 20px; /* 与下方元素的间距 */
}

.retry-container {
  margin-top: 20px; /* 与上方元素的间距 */
}

.retry-button {
  background-color: #2196f3; /* 按钮背景色 */
  color: #fff; /* 按钮文字颜色 */
  padding: 10px 20px;
  font-size: 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}
</style>
