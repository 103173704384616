<template>
  <el-dialog
    :visible.sync="internalDialogVisible"
    title="修改密码"
    margin-top="20vh"
    width="50%"
    :before-close="beforeClose"
    class="userProfileDialog"
    center
  >
    <el-card class="user-form">
      <el-form
        ref="userForm"
        label-position="left"
        label-width="100px"
        :rules="userFormRules"
        :model="userForm"
      >
        <div class="notice">密码不会储存在任何地方，请保存好您的密码</div>
        <!-- 密码 -->
        <el-form-item label="新密码" prop="password">
          <div class="input-wrapper">
            <el-input
              placeholder="请输入密码"
              v-model="userForm.password"
              show-password
            ></el-input>
          </div>
        </el-form-item>
        <!-- 确认密码 -->
        <el-form-item label="确认密码" prop="password2">
          <div class="input-wrapper">
            <el-input
              placeholder="请确认密码"
              v-model="userForm.password2"
              show-password
            ></el-input>
          </div>
        </el-form-item>
        <!-- 手机号码 -->
        <el-form-item label="手机号码">
          <div class="input-wrapper">
            <el-input v-model="userForm.phone" disabled></el-input>
          </div>
        </el-form-item>
        <!-- 验证码 -->
        <el-form-item label="验证码">
          <div class="input-wrapper">
            <el-input v-model="userForm.code"></el-input>
            <el-button
              class="codeButton"
              size="small"
              round
              type="primary"
              @click="getCode"
            >
              {{ countdown > 0 ? countdown + "s" : "获取验证码" }}
            </el-button>
          </div>
        </el-form-item>
        <!-- 其他表单项... -->
      </el-form>
      <!-- 按钮 -->
      <div class="buttonList">
        <el-button
          size="small"
          type="primary"
          round
          class="update-user-button"
          @click="updateUser"
        >
          保存
        </el-button>
      </div>
    </el-card>
  </el-dialog>
</template>

<script>
import { setPassword, getCode, checkVerifyCode } from "../api";
import qs from "qs";
export default {
  props: {
    dialogVisible: Boolean,
  },
  data() {
    return {
      countdown: 0, // 添加倒计时功能
      outId: "",
      internalDialogVisible: this.dialogVisible,
      userForm: {
        id: "",
        password: "",
        password2: "",
        phone: "",
        code: "",
      },
      userInfo: {
        name: "",
        role: "",
        id: "",
        phone: "",
      },
      userFormRules: {
        password: [
          { required: true, message: "请输入密码", trigger: "blur" },
          { min: 6, message: "密码长度不能少于6位", trigger: "blur" },
        ],
        password2: [
          { required: true, message: "请再次输入密码", trigger: "blur" },
          {
            validator: (rule, value, callback) => {
              if (value !== this.userForm.password) {
                callback(new Error("两次输入密码不一致"));
              } else {
                callback();
              }
            },
            trigger: "blur",
          },
        ],
      },
    };
  },
  watch: {
    dialogVisible(newValue) {
      this.internalDialogVisible = newValue;
    },
  },
  //获取localsession
  created() {
    this.userInfo = JSON.parse(localStorage.getItem("userInfo"));
    console.log(this.userInfo, "userInfo");
    this.userForm.phone = this.userInfo.phone;
  },
  methods: {
    beforeClose(done) {
      this.$store.commit("updateUpdatePasswordDialogVisibility", false);
      done();
    },
    updateUser() {
      this.updateUserFrom = false;
      this.$refs.userForm.validate((valid) => {
        if (valid) {
          this.save();
          //关闭弹窗
          this.$store.commit("updateUpdatePasswordDialogVisibility", false);
        } else {
          this.$message({
            message: "请检查输入信息是否正确",
            type: "error",
          });
          return false;
        }
      });
    },
    cancel() {
      this.updateUserFrom = true;
    },
    save() {
      //校验验证码信息
      let obj = {
        phone: this.userForm.phone,
        outId: this.outId,
        code: this.userForm.code,
      };
      console.log(obj, "obj");
      const params = qs.stringify(obj);
      checkVerifyCode("/web/manage/checkVerifyCode", params).then(
        (response) => {
          if (response.data.code == 200) {
            //保存用户信息
            this.updateUserFrom = true;
            let setParams = {
              id: this.userInfo.id,
              password: this.userForm.password,
            };
            const params = qs.stringify(setParams);
            setPassword("web/user/setPassword", params).then((res) => {
              if (res.data.code === 200) {
                this.$message({
                  message: "修改成功",
                  type: "success",
                });
                //关闭弹窗
                this.$store.commit(
                  "updateUpdatePasswordDialogVisibility",
                  false
                );
              } else {
                this.$message({
                  message: res.data.message,
                  type: "error",
                });
              }
            });
          }
        }
      );
    },
    getCode() {
      const phone = this.userForm.phone;
      const params = qs.stringify({ phone: phone });
      //获取验证码
      getCode("/web/manage/sendVerifyCodeMsg", params).then((response) => {
        if (response.data.code === 200) {
          this.outId = response.data.data;
          this.$message({
            type: "success",
            message: "获取成功!",
          });
          // 启动倒计时并禁用按钮
          this.countdown = 60;
          const timer = setInterval(() => {
            this.countdown--;
            if (this.countdown <= 0) {
              clearInterval(timer);
            }
          }, 1000);
        } else {
          this.$message({
            type: "error",
            message: response.data.message,
          });
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.userProfileDialog {
  .el-dialog__body {
    text-align: center;
  }
  .user-form {
    padding: 20px;
    display: flex;
    flex-direction: column;
    .notice {
      color: #ff0000;
      font-size: 12px;
      margin-bottom: 20px;
    }
    .input-wrapper {
      display: flex;
      align-items: center;
      width: calc(100% - 100px);
      border: none;
      background-color: transparent;
      box-shadow: none;
      .el-input {
        width: 100%;
        text-align: right;
        margin-right: 10px;
      }
      .el-input {
        width: 100%;
      }
      .half-width {
        width: 50%;
      }
      .codeButton {
        margin-left: 10px;
      }
    }
  }
  .buttonList {
    margin-top: 50px;
    .update-user-button {
      margin-left: 100px;
    }
    .cancel {
      margin-left: 100px;
    }
  }
}
</style>
