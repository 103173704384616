<template>
  <div class="tabs">
    <!-- <el-tag
      v-for="(item, index) in tags"
      :key="item.path"
      :closable="item.name !== 'home'"
      :effect="$route.name === item.name ? 'dark' : 'plain'"
      @click="changeMenu(item)"
      @close="handleClose(item, index)"
      size="small"
    >
      {{ item.label }}
    </el-tag> -->
  </div>
</template>
<script>
import { mapState, mapMutations } from "vuex";
export default {
  name: "CommonTag",
  data() {
    return {};
  },
  computed: {
    ...mapState({
      tags: (state) => state.tab.tabsList,
    }),
  },
  methods: {
    ...mapMutations(["closeTag"]),
    //点击tag跳转
    changeMenu(item) {
      this.$router.push({ name: item.name });
    },
    //关闭tag
    handleClose(item, index) {
      this.closeTag(item, index);
      //获取当前tag的长度
      const length = this.tags.length - 1;
      //如果当前tag的索引小于当前tag的长度,则跳转到当前tag的下一个tag
      if (index < length) {
        this.$router.push({ name: this.tags[index + 1].name });
      } else {
        //如果当前tag的索引等于当前tag的长度,则跳转到当前tag的上一个tag
        this.$router.push({ name: this.tags[index - 1].name });
      }
    },
  },
};
</script>
<style lang="less" scoped>
.tabs {
  padding: 20px;
  .el-tag {
    margin-right: 15px;
    cursor: pointer;
  }
}
</style>
